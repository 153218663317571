import React from "react"
import { graphql } from "gatsby"
import { MdCopyright } from "@react-icons/all-files/md/MdCopyright"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import FeaturedImage from "../../components/featuredImage"

// import "../../assets/Scss/templates/posts.scss"
import "../../assets/Scss/templates/campaign.scss"

const Causes = ({ data }) => {
  const { page } = data
  const {
    title,
    uri,
    content,
    featuredImage,
    excerpt,
    databaseId,
  } = page


  return (
    <Layout
      bodyClass={`single single-campaign postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}
    >
      <Seo
        title={title}
        description={excerpt}
        socialImage={featuredImage?.node}
        uri={uri}
      />

      <article
        className={`campaign-${databaseId} campaign type-campaign status-publish format-standard has-post-thumbnail hentry category-uncategorized`}
        id={`campaign-${databaseId}`}
      >
        <header className="entry-header has-text-align-center header-footer-group">
          {/* <div
            className="theBG"
            style={
              {
                // backgroundImage: `url(${featuredImage?.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src})`,
              }
            }
          >
            <FeaturedImage image={featuredImage} />
          </div> */}
          <div className="container entry-content head-wrapper">
            <div className="entry-header-inner section-inner medium">
              <h1
                className="entry-title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>
            <div className="featured-wrapper">
              <FeaturedImage image={featuredImage} />
              <div className="caption">
                {featuredImage?.node.caption ? <MdCopyright /> : ""}
                <div
                  dangerouslySetInnerHTML={{
                    __html: featuredImage.node.caption,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </header>

        <div className="container post-inner thin">
          <div
            className="entry-content"
            dangerouslySetInnerHTML={{
              __html: content.replace(/<p>&nbsp;<[/]p>/g, ""),
            }}
          />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query Causes($id: String!, $nextPage: String, $previousPage: String) {
    page: wpCauses(id: { eq: $id }) {
      ...CausesContent
    }
    nextPage: wpCauses(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpCauses(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`

export default Causes
